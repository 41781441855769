import __layout from 'nextra-theme-docs'
import __themeConfig from '/vercel/path0/theme.config.tsx'

import 'katex/dist/katex.min.css'
import 'nextra-theme-docs/style.css'
import React from "react";
// import "highlight.js/styles/vs2015.css";
import "../textEditor.css";
import "../globals.css";
import Script from "next/script";

// This default export is required in a new `pages/_app.js` file.
export default function MyApp({ Component, pageProps }) {
  const isProduction = process.env.NODE_ENV === "production";

  return (
    <>
      {isProduction && (
        <Script
          src="https://plausible.io/js/script.js"
          defer
          data-domain="docs.pluto.xyz"
        />
      )}
      <Component {...pageProps} />
    </>
  );
}


const __nextra_internal__ = globalThis[Symbol.for('__nextra_internal__')] ||= Object.create(null)
__nextra_internal__.context ||= Object.create(null)
__nextra_internal__.Layout = __layout
__nextra_internal__.themeConfig = __themeConfig