import { useCallback, useEffect, useRef, useState } from "react";
import { useTheme } from "next-themes";

export function useIsMounted() {
  const isMounted = useRef(false);

  useEffect(() => {
    // When the component mounts, set isMounted to true
    isMounted.current = true;

    // Cleanup function to set isMounted to false when the component unmounts
    return () => {
      isMounted.current = false;
    };
  }, []);

  // Optional: Provide a method to check the mounted state
  const checkIsMounted = useCallback(() => isMounted.current, []);

  return checkIsMounted;
}

export const useEffectiveTheme = () => {
  const { theme, systemTheme } = useTheme();
  const [effectiveTheme, setEffectiveTheme] = useState<string>("light");
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (!mounted) return;

    // Determine the effective theme considering 'system' preference
    const themeToUse = theme === "system" ? systemTheme : theme;
    setEffectiveTheme(themeToUse as "light" | "dark");
  }, [theme, systemTheme, mounted]);

  return { effectiveTheme, mounted };
};
